@import "../assets/styles/Variables.scss";

.loading-main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($dark, 0.7);
  z-index: 999;
  img {
    border-radius: 110px;
    width: 230px;
    height: 230px;
  }

  // Full page loader styles

  @keyframes ldio-fr7iv3pd6t-o {
    0% {
      opacity: 1;
      transform: translate(0 0);
    }
    49.99% {
      opacity: 1;
      transform: translate(80px, 0);
    }
    50% {
      opacity: 0;
      transform: translate(80px, 0);
    }
    100% {
      opacity: 0;
      transform: translate(0, 0);
    }
  }
  @keyframes ldio-fr7iv3pd6t {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(80px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .ldio-fr7iv3pd6t div {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    top: 60px;
    left: 20px;
  }
  .ldio-fr7iv3pd6t {
    p {
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .ldio-fr7iv3pd6t div:nth-child(1) {
    background: $primary;
    animation: ldio-fr7iv3pd6t 1s linear infinite;
    animation-delay: -0.5s;
  }
  .ldio-fr7iv3pd6t div:nth-child(2) {
    background: $white;
    animation: ldio-fr7iv3pd6t 1s linear infinite;
    animation-delay: 0s;
  }
  .ldio-fr7iv3pd6t div:nth-child(3) {
    background: $primary;
    animation: ldio-fr7iv3pd6t-o 1s linear infinite;
    animation-delay: -0.5s;
  }
  .loadingio-spinner-dual-ball-hchn3d2m5bs {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-fr7iv3pd6t {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-fr7iv3pd6t div {
    box-sizing: content-box;
  }
}
