@import "./Variables.scss";
@import "react-phone-input-2/lib/style.css";

* {
  font-family: "Raleway", sans-serif;
}
  
html {
    -webkit-text-size-adjust: none;
}

ion-content {
  --background: #{$baseColor};
}

.theme-page {
  padding: 20px 0px;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.w-100 {
  width: 100% !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
    max-width: 1140px;
  }
}

.form-error {
  .form-group {
    margin-bottom: 0px !important;
  }
  .dy-error {
    margin: 0;
    padding: 12px 10px;
    background: #dc3545;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
}

//form-group
.form-group {
  margin-bottom: 20px;
  .disabled-opacity-1{
    input{
      opacity: 1 !important;
    }
   }
  ion-label {
    //--color: #{rgba($white, 0.8)};
    color: rgba($white, 0.8);
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 0.5rem;
    display: inline-block;
  }

  .form-control {
    --background: #{$tertiary};
    --color: #{$white};
    border-radius: 2px;
  }

  .right-icon-input {
    position: relative;

    .form-control {
      --padding-end: 40px;
    }

    ion-icon {
      position: absolute;
      top: 12px;
      right: 10px;
      z-index: 2;
      color: $white;
    }
  }

  .custom-control {
    background-color: $tertiary;
    color: $white;
    border-radius: 2px;
    outline: none;
    box-shadow: none;
    padding: 10px;
    width: 100%;
  }

  ion-select {
    background: $tertiary;
    color: $white;
  }

  .custom-select {
    ion-range {
      --bar-background: #{$white};
      --bar-background-active: #0075ff;
      --bar-border-radius: 100px;
      --bar-height: 10px;
      --height: 20px;
      --pin-background: #0075ff;
      --knob-size: 20px;
    }
  }
}

//ion-checkbox
ion-checkbox {
  --border-color: #454545;
  --background: transparent;
  --background-checked: #ff9100;
  --border-radius: 0px;
  --checkmark-color: #fff;
  --border-color-checked: #ff9100;
  height: 20px;
  width: 20px;
}

//theme-button
.theme-button {
  text-transform: capitalize;

  &.primary-btn {
    --background: #{$primary};
  }

  &.danger-btn {
    --background: #{$danger};
  }

  &.grey-btn {
    --background: #{$grey};
  }

  &.success-btn {
    --background: #{$success};
  }
}

//message info
.message {
  padding: 3px 0px;

  p {
    font-size: 12px;
    font-weight: 400;
    margin: 0px;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 5px;
    // ion-icon {
    //   margin-right: 4px;
    //   margin-top: -17px;
    // }
  }

  &.error {
    p {
      color: $danger;
    }
  }

  &.success {
    p {
      color: $success;
    }
  }

  &.info-message {
    p {
      color: $white;
    }
  }
}

//theme-table
.theme-table {
  margin-bottom: 20px;

  .table-responsive {
  }

  table {
    width: 100%;
    background-color: #343a40;

    tr {
      td {
        color: $white;
        font-size: 0.9rem;
        line-height: 1.6;
        border-color: #454d55;
        padding: 0.75rem;
      }
    }

    thead {
      tr {
        td {
          border-bottom: 2px solid #454d55;
          border-top: 1px solid #454d55;
        }
      }
    }

    tbody {
      tr {
        td {
          border-top: 1px solid #454d55;
        }

        &:hover {
          td {
            background-color: rgba(255, 255, 255, 0.075);
          }
        }
      }

      .table-info {
        h3 {
          font-size: 1.1em;
          font-weight: 300;
          line-height: 1.7em;
          color: #999;
          margin: 0px;
        }

        p {
          font-size: 12px;
          font-weight: 300;
          line-height: 16px;
          color: #999;
          margin: 0px;
        }
      }
    }
  }

  //theme-table-dark
  &.theme-table-dark {
    tbody {
      tr {
        td {
          border-top: 15px solid #454d55;
          background-color: $tertiary;
        }

        &:hover {
          td {
            background-color: $tertiary;
          }
        }

        &:nth-child(1) {
          td {
            border-top: 1px solid #454d55;
          }
        }
      }
    }
  }
}

//common-tab
.common-tab {
  .tab-menu {
    margin-bottom: 20px;

    ul {
      display: flex;
      align-items: center;
      margin: 0px;
      padding: 0px;
      width: 100%;

      li {
        list-style: none;
        width: 50%;
        cursor: pointer;

        span {
          text-align: center;
          text-transform: uppercase;
          display: inline-block;
          background-color: #404040;
          color: white;
          width: 100%;
          padding: 10px;
          border-bottom: 2px solid transparent;

          &.active {
            background-color: #454545;
            border-color: #ff9100;
          }
        }
      }
    }
  }

  .tab-contant {
  }
}

//dash-time-info
.dash-time-info {
  .info-item {
    text-align: center;
    margin-bottom: 10px;

    svg {
      stroke: $primary;
      width: 24px;
      min-height: 24px;
    }

    span {
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.6;
      display: inline-block;
      display: block;
      color: $white;
    }
  }
}

//theme-card
.theme-card {
  background-color: #353535;
  border-color: #404040;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 15px;

  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    .heading {
      h2 {
        font-size: 1.125rem;
        font-weight: $semiBold;
        line-height: 1.2;
        margin: 0px;
        color: $white;
        border-color: #454d55;
      }
    }

    .action-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    thead {
      tr {
        td {
          border-bottom: 2px solid #454d55;
          border-top: 1px solid #454d55;
        }
      }
    }

    tbody {
      tr {
        td {
          border-top: 1px solid #454d55;
        }

        &:hover {
          td {
            background-color: rgba(255, 255, 255, 0.075);
          }
        }
      }
    }
  }
}

//common-tab
.common-tab {
  .tab-menu {
    margin-bottom: 20px;

    ul {
      display: flex;
      align-items: center;
      margin: 0px;
      padding: 0px;
      width: 100%;

      li {
        list-style: none;
        width: 50%;

        span {
          text-align: center;
          text-transform: uppercase;
          display: inline-block;
          background-color: #404040;
          color: white;
          width: 100%;
          padding: 10px;
          border-bottom: 2px solid transparent;

          &.active {
            background-color: #454545;
            border-color: #ff9100;
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {
      ul {
        li {
          span {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }

  .card-mid {
    padding: 1.25rem;
  }
}

//theme-modal
.theme-modal {
  --background: #{$secondary};
  --border-color: #404040;
  --border-radius: 0.3rem;
  --width: 90%;
  --max-width: 800px;
  --max-height: 90%;
  --border-width: 1px;
  --border-style: solid;

  #ion-react-wrapper{
    border: 1px solid rgba($white, 0.08);
  }
  &.confirm-modal {
    --max-height: 250px;
    --max-width: 500px;
  }

  &.modal-h300 {
    --max-height: 300px;
    --max-width: 500px;
  }

  ion-header {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
    background-color: $baseColor;

    .heading {
      width: calc(100% - 60px);

      h2 {
        color: $white;
        margin: 0px;
      }
    }

    .modal-closer {
      width: 60px;
      min-width: 60px;
      text-align: right;

      ion-button {
        --background: transparent;
        --background-hover: transparent;
        --padding-bottom: 0px;
        --padding-end: 0px;
        --padding-start: 0px;
        --padding-top: 0px;
        height: auto;

        ion-icon {
          color: rgba($white, 0.8);
        }
      }
    }
  }

  .modal-body {
    //progress-image
    .progress-image {
      ion-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .units-info {
      p {
        color: $white;
        font-size: 14px;
        line-height: 18px;

        a {
          color: $white;
        }

        &:nth-child(1) {
          border-bottom: 1px solid rgba($dark, 0.08);
          padding-bottom: 20px;
        }
      }
    }
  }

  .modal-bottom {
    padding-top: 1rem;
  }
  &.video-modal {
    --max-height: 500px;
  }
}

//align css
.text-center {
  text-align: center;
}

//color css
.white {
  color: $white !important;
}

//zdfdzx
.opacity-05 {
  opacity: 0.5;
}

//hr
hr {
  background-color: rgba($white, 0.05);
}

//margin css
.mb-0 {
  margin-bottom: 0px !important;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

//custom-drop
.custom-drop {
  position: relative;
  display: inline-block;

  .drop-list {
    position: absolute;
    right: 0;
    background-color: #353535;
    bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;

    span {
      color: $white;
      display: block;
      padding: 10px 12px;
      cursor: pointer;
      font-size: 14px;
      line-height: 18px;
      white-space: nowrap;
      text-align: left;

      &:hover {
        color: $primary;
      }
    }
  }
}

//flex css
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

//pills
.pills {
  padding: 4px 10px;
  border-radius: 6px;
  display: inline-block;

  p {
    font-size: 10px;
    line-height: 14px;
    color: $white;
    margin: 0px;
  }

  &.error {
    background-color: $danger;
  }

  &.success {
    background-color: $success;
  }

  &.info-message {
    background-color: $dark;
  }
}

//btn-bottom-right
.btn-bottom-right {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

//common-pagination
.common-pagination {
  overflow-y: auto;

  //pagination
  .pagination {
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    overflow: hidden;
    width: fit-content;

    li {
      list-style: none;

      a {
        color: white;
        background-color: #505050;
        border-color: #505050;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
      }

      &.disabled {
        a {
          background: #404040;
          border-color: #404040;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      &:nth-child(2),
      &:nth-last-child(2) {
        // display: none;
        a {
          font-size: 10px;
        }
      }

      &.active,
      &:hover {
        a {
          background-color: #007bff;
          border-color: #007bff;
        }
      }
    }
  }
}

//spinner-text
.spinner-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  ion-spinner {
    margin-right: 10px;
  }

  p {
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    color: $white;
  }

  //ion-spinner
  ion-spinner {
    --color: #{$white};
  }
}

//custom-toast
.custom-toast {
  max-width: 500px;
  width: 100%;
  background-color: $white;
  position: fixed;
  top: 15px;
  right: 15px;
  border-radius: 6px;
  overflow: hidden;
  .toast-inner {
    position: relative;
    padding: 15px 15px;
    p {
      font-size: 12px;
      font-weight: 400;
      margin: 0px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $dark;
      padding-right: 20px;
      font-weight: 500;
    }
    ion-icon {
      margin-right: 4px;
      position: absolute;
      top: 5px;
      right: 1px;
      cursor: pointer;
      font-size: 18px;
    }
    &:after {
      content: "";
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: transparent;
      position: absolute;
    }
  }

  &.error {
    .toast-inner {
      ion-icon {
        color: $danger;
      }
      &:after {
        background-color: $danger;
      }
    }
  }

  &.success {
    .toast-inner {
      ion-icon {
        color: $success;
      }
      &:after {
        background-color: $success;
      }
    }
  }
}

//ct-toast
.ct-toast {
  .ct-text-group {
    .ct-text {
      .toast-inner {
        position: relative;
        p {
          font-size: 12px;
          font-weight: 400;
          margin: 0px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: $dark;
          padding-right: 20px;
          font-weight: 500;
        }
        ion-icon {
          margin-right: 4px;
          position: absolute;
          top: -1px;
          right: -12px;
          cursor: pointer;
          font-size: 18px;
          color: rgba($dark, 0.8);
        }
      }
    }
  }
}

.theme-radio {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  border: 2px solid $grey;
  --border-width: 0px;
  --color-checked: transparent;
}

.theme-radio.radio-checked {
  border-color: $primary;
}

.theme-radio.radio-checked::part(mark) {
  background: $primary;
  border-width: 0px;
  border-radius: 100%;
  width: 16px;
  height: 16px;
}

//inline-radio
.inline-radio {
  display: flex;
  align-items: center;

  .radio-label {
    display: flex;
    align-items: center;
    margin-right: 15px;

    .theme-radio {
      margin-right: 8px;
    }

    ion-label {
      color: $white;
      font-size: 14px;
      line-height: 18px;
      margin: 0px;
    }
  }
}

.primary {
  color: $primary !important;
}

.flag-dropdown {
  width: 25px;
  height: 25px;
  margin-top: 5px;
  border-radius: 0 !important;
  border: none !important;
  background-color: transparent !important;
}
.selected-flag {
  height: 25px !important;
  width: 25px !important;
  padding-left: 4px !important;
}
.react-tel-input .form-control {
  padding-left: 31px;
}

//common-modal
.common-modal {
  &.date-time-modal{
    --width: 100%;
    --max-width:300px;
    --max-height:396px;
    ion-datetime {
      color: $dark;
    }
  }
  &.time-picker{
    --max-height: 255px;
  }
}


//time-control
.time-control{
  background-color: #212529;
  position: relative;
  .theme-button{
    width: fit-content;
    color: #fff;
    width: 100%;
    p{
      font-family: "Gill Sans", sans-serif !important;
      position: absolute;
      left: 0;
      // top: -3px;
    }

    ion-icon{
      position: absolute;
      right: 0;
      top: 9px;

    }
    
  }
}

